import Vue from 'vue'
import Component from 'vue-class-component'
import sections from '../defaultNavigationDrawerSections'
import Layout from '@/apps/core/components/Layout'
import NoteListTable from '../../components/tables/NoteListTable/NoteListTable.vue'

@Component({
  components: {
    Layout,
    NoteListTable
  },
  props: {},
  computed: {}
})

class NotesListView extends Vue {
  sections = sections
  title = null

  created () {
    this.title = this.$t('notes.listSectionName')
  }
}

export default NotesListView
